import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/App';
import {setup} from "goober";
// @ts-ignore
import {jsx} from 'react/jsx-runtime';
import BuildInfo from "./BuildInfo";

setup(jsx);
window['buildInfo'] = BuildInfo;

ReactDOM.render(<App/>, document.getElementById('root'));

const getRidOfAllServiceWorkers = async () => {
    const registry = await navigator.serviceWorker.getRegistrations()
    for (const entry of registry) {
        entry.unregister()
    }
}

if (window.location.hostname === 'localhost') {
    getRidOfAllServiceWorkers();
} else {
    navigator.serviceWorker && navigator.serviceWorker.register('/sw.js');
}
